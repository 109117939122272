import React, { ReactNode } from 'react'

import { DefaultMetadata } from './components/Metadata'

interface WrapRootElementProps {
  element: ReactNode
}

export const wrapRootElement = ({
  element,
}: WrapRootElementProps): ReactNode => (
  <>
    <DefaultMetadata />
    {element}
  </>
)
