import React from 'react'
import { Helmet } from 'react-helmet'

export const DefaultMetadata = () => (
  <Helmet>
    <title>Capture</title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, user-scalable=no"
    />
  </Helmet>
)
